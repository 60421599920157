#header {
  background: $dark-grey;
  font-family: Century Gothic;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 2rem 0;
  margin-bottom: 3rem;
  #nav {
    @include boot-menu($dark-grey, 'center' , $dark-grey);
    ul {
      margin: 3rem 0;
      li {
        margin: 1rem 1rem;
        a {
          display: block;
          padding: 1rem;
          background: $red;
          border: 1px solid $gray;
          color: white;
          @include box-shadow(0, 0, 3px, #1B1B1B, inset);
          @include border-radius(3px);
          &:hover {
            background: lighten($red,10%);
          }
        }
      }
    }
  }
  form {
    text-align: center;
    .form-control,
    .form-submit {
      padding: 5px;
      border: 1px solid #1B1B1B;
      @include box-shadow(0, 0, 3px, #1B1B1B, inset);
      @include border-radius(3px);
    }
    .form-control {
      padding: 5px 2px;
      background: #414141;
      color: white;
    }
    .form-submit {
      margin: 1rem 0;
      display: inline-block;
      background: #ECEBEB;
      color: black;
    }
  }
}