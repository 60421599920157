.font-base {
  font-family: arial, sans-serif; }

/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */
/**
 * $BUTTON
 */
.a-left {
  float: left; }

.a-right {
  float: right; }

a {
  -webkit-transition: all 0.4s ease 0s;
  -khtml-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-decoration: none !important;
  outline: none !important; }

body {
  background: #F2F1F1 url("../img/bg.png") repeat;
  margin: 0px;
  padding: 0px; }

#table-products {
  margin: 0 1rem; }
  #table-products table {
    width: 100%;
    border: 1px solid #000;
    border-collapse: collapse;
    font-size: 11px;
    color: #100035;
    background: #F2F1F1; }
    #table-products table tr:nth-child(odd) {
      background: #DBDADA; }
    #table-products table th, #table-products table td {
      border: 1px solid #000;
      padding: 5px; }
    #table-products table th {
      font-weight: 300; }
    #table-products table td img {
      display: block;
      max-width: 228px;
      height: auto; }

#header {
  background: #525252;
  font-family: Century Gothic;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 2rem 0;
  margin-bottom: 3rem; }
  #header #nav .navbar-collapse {
    margin: 0;
    padding: 0;
    border: none;
    background-color: #525252; }
    #header #nav .navbar-collapse.in {
      overflow-y: hidden;
      overflow-x: hidden; }
    #header #nav .navbar-collapse ul.menu {
      width: -moz-fit-content;
      width: -webkit-fit-content;
      width: fit-content;
      margin: 0 auto; }
      @media (max-width: 768px) {
        #header #nav .navbar-collapse ul.menu {
          width: 100%; }
          #header #nav .navbar-collapse ul.menu li {
            float: none;
            width: 100%; } }
  #header #nav ul.menu {
    margin: 0;
    padding: 0;
    display: block;
    text-align: center; }
    #header #nav ul.menu:before, #header #nav ul.menu:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
      float: none; }
    #header #nav ul.menu li {
      float: none;
      display: inline-block; }
  #header #nav ul {
    margin: 3rem 0; }
    #header #nav ul li {
      margin: 1rem 1rem; }
      #header #nav ul li a {
        display: block;
        padding: 1rem;
        background: #DA0000;
        border: 1px solid #1B1B1B;
        color: white;
        -webkit-box-shadow: 0 0 3px #1B1B1B inset;
        -moz-box-shadow: 0 0 3px #1B1B1B inset;
        box-shadow: 0 0 3px #1B1B1B inset;
        -webkit-border-radius: 3px;
        -khtml-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px; }
        #header #nav ul li a:hover {
          background: #ff0e0e; }
  #header form {
    text-align: center; }
    #header form .form-control, #header form .form-submit {
      padding: 5px;
      border: 1px solid #1B1B1B;
      -webkit-box-shadow: 0 0 3px #1B1B1B inset;
      -moz-box-shadow: 0 0 3px #1B1B1B inset;
      box-shadow: 0 0 3px #1B1B1B inset;
      -webkit-border-radius: 3px;
      -khtml-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px; }
    #header form .form-control {
      padding: 5px 2px;
      background: #414141;
      color: white; }
    #header form .form-submit {
      margin: 1rem 0;
      display: inline-block;
      background: #ECEBEB;
      color: black; }

