body {
  background: #F2F1F1 url("../img/bg.png") repeat;
  margin: 0px;
  padding: 0px;
}
#table-products {
  margin: 0 1rem;
  table {
    width: 100%;
    border: 1px solid #000;
    border-collapse: collapse;
    font-size: 11px;
    color: #100035;
    background: #F2F1F1;
    tr {
      &:nth-child(odd){
        background: #DBDADA;
      }
    }
    th,
    td {
      border: 1px solid #000;
      padding: 5px;
    }
    th {
      font-weight: 300;
    }
    td {
      img {
        display: block;
        max-width: 228px;
        height: auto;
      }
    }

  }
}
